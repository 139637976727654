/* header.css - Masaüstü korunarak mobil burger menü eklemeleri */

/* Masaüstü header stilleri */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(90deg, rgba(46, 74, 97, 0.9), rgba(75, 120, 170, 0.8));
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

header img {
  height: 80px;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin: 0;
  padding-right: 50px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;
}

nav ul li a:hover {
  color: #ff007f;
}

.register-btn {
  background-color: #ff007f;
  padding: 8px 16px;
  border-radius: 25px;
  font-weight: bold;
  color: white;
  transition: background-color 0.3s ease;
}

.register-btn:hover {
  background-color: #ff66a3;
}

/* Mobil için burger menü ve responsive ayarlar */
.burger-menu {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  align-items: center;
  background: rgba(46, 74, 97, 0.95);
  position: absolute;
  top: 100px;
  width: 100%;
  padding: 20px 0;
  gap: 10px;
}

.mobile-nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px;
}

/* Mobil medya sorguları */
@media screen and (max-width: 768px) {
  nav ul {
    display: none;
  }

  .burger-menu {
    display: block;
  }

  .mobile-nav {
    display: flex;
  }

  header img {
    height: 60px;
  }
}